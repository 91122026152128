import { useState } from "react";
import green from "../images/results/Green.svg";
import "../stylesheets/results-marketing.scss";
import AttributeLi from "./AttributeLi.jsx";

const AttributeAttitudes = ({ attributeData }) => {
  const [showAllAtt, setShowAllAtt] = useState(false);

  function handleAttClick() {
    setShowAllAtt((showAllAtt) => {
      return !showAllAtt;
    });
  }

  const allAttitudes = attributeData.data[3]?.attitudes || [];
  const initiallyVisibleAttitudes = allAttitudes.slice(0, -3);
  const additionalAttitudes = allAttitudes.slice(-3);

  return (
    <section className="attribute-category">
      <span className="attribute-type-container">
        <img className="single-puzzle" alt="" src={green} />
        <span className="attribute-type">Attitudes</span>
      </span>
      <ul className="attribute-list attitude">
        {initiallyVisibleAttitudes.map((att, index) => (
          <AttributeLi attribute={att.attribute} index={index} average={att.average} />
        ))}
        {showAllAtt && (
          <>
            {additionalAttitudes.map((att, index) => (
              <AttributeLi attribute={att.attribute} index={index} average={att.average} />
            ))}
          </>
        )}
      </ul>
      {showAllAtt ? (
        <button className="less" onClick={handleAttClick}>
          Less
        </button>
      ) : (
        <button className="view-all" onClick={handleAttClick}>
          View All
        </button>
      )}
    </section>
  );
};

export default AttributeAttitudes;
