import "../stylesheets/results-marketing.scss";

const AttributeLi = ({ attribute, index, average }) => {
  return (
    <li key={index}>
      <span className="attribute-name">
        {attribute}
        <span className="attribute-avg"> AVG: {average}</span>
      </span>
    </li>
  );
};

export default AttributeLi;
