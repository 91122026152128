import { useState } from "react";
import red from "../images/results/Red.svg";
import "../stylesheets/results-marketing.scss";
import AttributeLi from "./AttributeLi.jsx";

const AttributeSkills = ({ attributeData }) => {
  const [showAllSkills, setShowAllSkills] = useState(false);

  function handleSkillsClick() {
    setShowAllSkills((showAllSkills) => {
      return !showAllSkills;
    });
  }

  const allSkills = attributeData.data[0]?.skills || [];
  const initiallyVisibleSkills = allSkills.slice(0, -3);
  const additionalSkills = allSkills.slice(-3);

  return (
    <section className="attribute-category">
      <img className="single-puzzle" alt="" src={red} />
      <span className="attribute-type">Skills</span>
      <ul className="attribute-list skills">
        {initiallyVisibleSkills.map((skill, index) => (
          <li key={index}>
            <span className="attribute-name">
              {skill.attribute}
              <span className="attribute-avg"> AVG: {skill.average}</span>
            </span>
          </li>
        ))}
        {showAllSkills && (
          <>
            {additionalSkills.map((skill, index) => (
              <li key={index}>
                <span className="attribute-name">
                  {skill.attribute}
                  <span className="attribute-avg"> AVG: {skill.average}</span>
                </span>
              </li>
            ))}
          </>
        )}
      </ul>
      {showAllSkills ? (
        <button className="less" onClick={handleSkillsClick}>
          Less
        </button>
      ) : (
        <button className="view-all" onClick={handleSkillsClick}>
          View All
        </button>
      )}
    </section>
  );
};

export default AttributeSkills;
