import { useState } from "react";
import { compile } from "sass";
import gray from "../images/results/Gray.svg";
import "../stylesheets/results-marketing.scss";
import AttributeLi from "./AttributeLi.jsx";

const AttributeCompetencies = ({ attributeData }) => {
  const [showAllComp, setShowAllComp] = useState(false);

  function handleCompClick() {
    setShowAllComp((showAllComp) => {
      return !showAllComp;
    });
  }

  const allComp = attributeData.data[2]?.competencies || [];
  const initiallyVisibleComp = allComp.slice(0, -3);
  const additionalComp = allComp.slice(-3);

  return (
    <section className="attribute-category">
      <span className="attribute-type">
        <img className="single-puzzle" alt="" src={gray} />
        Competencies
      </span>
      <ul className="attribute-list competency">
        {initiallyVisibleComp.map((comp, index) => (
          <AttributeLi attribute={comp.attribute} index={index} average={comp.average} />
        ))}
        {showAllComp && (
          <>
            {additionalComp.map((comp, index) => (
              <AttributeLi attribute={comp.attribute} index={index} average={comp.average} />
            ))}
          </>
        )}
      </ul>
      {showAllComp ? (
        <button className="less" onClick={handleCompClick}>
          Less
        </button>
      ) : (
        <button className="view-all" onClick={handleCompClick}>
          View All
        </button>
      )}
    </section>
  );
};

export default AttributeCompetencies;
